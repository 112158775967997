export const setBet = (checkbox, square, amount, rouletteType) => {
  const checkValue = Number(checkbox.name);
  const calleNumbers = [1, 4, 7, 10, 13, 16, 19, 22, 25, 28, 31, 34];

  if (
    (square < 4 && checkbox.className === 'semipleno') ||
    checkbox.className === 'cubre'
  ) {
    const result = oneZeroRoulette(
      checkbox,
      square,
      amount,
      calleNumbers,
      checkValue,
      rouletteType
    );

    return result;
  }

  if (checkbox.className === 'linea' && calleNumbers.includes(square)) {
    let number = [];

    for (
      let currentNumber = square;
      currentNumber <= square + 5;
      currentNumber++
    ) {
      number.push({ number: currentNumber, amount });
    }

    return { type: 'lineaNumbers', number };
  }

  if (checkbox.className.includes('calle') && calleNumbers.includes(square)) {
    let numbers = [];
    for (
      let currentNumber = square;
      currentNumber <= square + 2;
      currentNumber++
    ) {
      numbers.push({ number: currentNumber, amount });
    }

    return { type: 'calleNumbers', number: numbers };
  }

  if (
    checkbox.className.includes('semiplen') &&
    checkbox.className.includes('horizontal')
  ) {
    return {
      type: 'semiPlenoNumbers',
      number: [
        { number: square, amount },
        { number: square - 1, amount },
      ],
    };
  }

  if (checkbox.className === 'square') {
    let number = [];
    let cont = 0;
    for (
      let currentNumber = square - 1;
      currentNumber <= square + 3;
      currentNumber++
    ) {
      cont++;
      if (cont === 3) continue;
      number.push({ number: currentNumber, amount });
    }

    return { type: 'cuadroNumbers', number };
  }
  if (
    square > 3 &&
    !checkbox.className.includes('calle') &&
    !checkbox.className.includes('square') &&
    !checkbox.className.includes('linea')
  ) {
    return {
      type: 'semiPlenoNumbers',
      number: [
        { number: square - 3, amount },
        { number: checkValue, amount },
      ],
    };
    // return semiPleno;
  } else if (
    !checkbox.className.includes('calle') &&
    !checkbox.className.includes('square') &&
    !checkbox.className.includes('linea')
  ) {
    return {
      type: 'semiPlenoNumbers',
      number: [checkValue, checkValue - checkValue],
    };
    // return semiPleno;
  }
};

const oneZeroRoulette = (
  checkbox,
  square,
  amount,
  calleNumbers,
  checkValue,
  rouletteType
) => {
  if (checkbox.className === 'semipleno' && square < 4) {
    if (rouletteType === 'normal') {
      const numbers = [
        {
          number: square,
          amount,
        },
        {
          number: 0,
          amount,
        },
      ];

      return { type: 'semiPlenoNumbers', number: numbers };
    } else if (rouletteType === 'doubleZero') {
      if (square === 1) {
        const numbers = [
          {
            number: square,
            amount,
          },
          {
            number: 0,
            amount,
          },
        ];
        return { type: 'semiPlenoNumbers', number: numbers };
      }

      if (square === 2) {
        return { type: 'cubre', number: { type: '0-37-2', amount } };
      }
      if (square === 3) {
        const numbers = [
          {
            number: square,
            amount,
          },
          {
            number: 37,
            amount,
          },
        ];
        return { type: 'semiPlenoNumbers', number: numbers };
      }
    }
  }

  if (checkbox.className === 'cubre') {
    const number = Number(checkbox.id.split(' ')[0]);
    const type = checkbox.id.split(' ')[1];
    const numbers = [];

    if (type === 'linea') {
      for (let currentNumber = 0; currentNumber < 4; currentNumber++) {
        numbers.push({ number: currentNumber, amount });
      }

      if (rouletteType === 'normal') {
        return { type: 'lineaNumbers', number: numbers };
      } else if (rouletteType === 'doubleZero') {
        numbers.push({ number: 37, amount });
        return { type: 'specialCalle', number: numbers };
      }
    }

    if (number === 3) {
      if (rouletteType === 'normal') {
        return { type: 'cubre', number: { type: '0-2-3', amount } };
      }

      if (rouletteType === 'doubleZero') {
        return { type: 'cubre', number: { type: '37-2-3', amount } };
      }
    }

    if (number === 2) {
      if (rouletteType === 'normal') {
        return { type: 'cubre', number: { type: '0-1-2', amount } };
      }

      if (rouletteType === 'doubleZero') {
        return { type: 'cubre', number: { type: '0-1-2', amount } };
      }
    }
  }

  console.log(calleNumbers, checkValue);
};
