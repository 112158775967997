<template>
  <div class="container">
    <Launch @launch="launch" ref="launchComponent" />
    <div class="d-flex justify-content-between">
      <label class="toggle" for="uniqueID">
        <input
          type="checkbox"
          class="toggle__input"
          id="uniqueID"
          @click="handleDoubleZeroCheck"
        />
        <span class="toggle-track">
          <span class="toggle-indicator">
            <!-- 	This check mark is optional	 -->
          </span>
        </span>
        {{
          doubleZeroRoulette
            ? 'Desactivar ruleta doble 00'
            : 'Activar ruleta doble 00'
        }}
      </label>
      <router-link to="/">
        <span class="text-end">
          <i
            class="uil uil-sign-out-alt font-size-18 align-middle me-1 text-muted"
          ></i
          >Ir al dashboard
        </span>
      </router-link>
    </div>
    <div class="text-muted display-6 text-center mb-4">
      <div v-if="roundOpen">
        Tiempo para <span>{{ timeResult }}</span
        >: {{ timeToBet }}
      </div>
      <div v-if="!roundOpen">{{ betMessage }}</div>
    </div>
    <div v-if="jackpotValues.length" class="jackpotContainer">
      <div
        v-for="(value, index) in jackpotValues"
        :key="index"
        class="jackpotItem"
      >
        <div class="jackpotNumber">{{ value.number }}</div>
        <div class="jackpotMultiplier">{{ value.multiplier }}X</div>
      </div>
    </div>
    <div
      class="position-absolute w-100 h-100 top-0 start-0 d-flex justify-content-center align-items-center loader flex-column"
      style="z-index: 1"
      v-if="loading"
    >
      <b-spinner label="Loading..."></b-spinner>
      <span class="fs-1">Buscando ronda... Espere por favor</span>
    </div>
    <div class="mx-auto mb-4 w-50">
      <b-progress height="2rem" :max="progressMax">
        <b-progress-bar
          :value="progressValue"
          animated
          :variant="barColor"
          class="mx-auto"
        >
          <span
            :class="`fw-bolder ${jackpotsOn ? 'text-dark' : 'text-light'}`"
            >{{ roundOpen ? 'HAGA SUS APUESTAS' : betMessage }}</span
          >
        </b-progress-bar>
      </b-progress>
    </div>

    <div :class="`table-container ${roundOpen ? 'openbets' : 'closebets'}`">
      <div class="tablero" ref="table">
        <div class="zeroBtn">
          <button
            data-betType="doble-zero"
            value="00"
            @click="(e) => handleClick(e, { number: 37, type: 'plenoNumbers' })"
            v-if="doubleZeroRoulette"
          >
            00
            <div
              class="HoverBtns inactive"
              :data-number="37"
              data-hover-number
            ></div>
          </button>
          <button
            data-betType="zero"
            value="0"
            @click="(e) => handleClick(e, { number: 0, type: 'plenoNumbers' })"
          >
            0
            <div
              class="HoverBtns inactive"
              :data-number="0"
              data-hover-number
            ></div>
          </button>
        </div>
        <div class="numbers-pleno">
          <span v-for="(button, index) in buttons" :key="index">
            <div
              :class="`btn-container ${button.color}`"
              :data-value="button.number"
            >
              <input
                type="checkbox"
                :name="`${button.number}`"
                :id="`${button.number}`"
                class="semipleno"
                :value="checkbox"
                @change="handleCheck"
                @mouseover="handleHover"
                @mouseleave="handleLeave"
              />
              <button
                :class="`innerBtn ${button.color}`"
                :value="button"
                @click="(e) => handleClick(e, button)"
              >
                {{ button.number }}
              </button>
              <input
                type="checkbox"
                name=""
                :id="`${button.number}`"
                class="calle semiplen horizontal"
                @change="handleCheck"
                @mouseover="handleHover"
                @mouseleave="handleLeave"
              />
              <input
                type="checkbox"
                name=""
                :id="`${button.number}`"
                class="square"
                @change="handleCheck"
                @mouseover="handleHover"
                @mouseleave="handleLeave"
                v-if="
                  ![1, 4, 7, 10, 13, 16, 19, 22, 25, 28, 31].includes(
                    button.number
                  )
                "
              />
              <input
                type="checkbox"
                name=""
                :id="`${button.number}`"
                class="linea"
                @change="handleCheck"
                @mouseover="handleHover"
                @mouseleave="handleLeave"
                v-if="
                  [1, 4, 7, 10, 13, 16, 19, 22, 25, 28, 31].includes(
                    button.number
                  )
                "
              />
              <input
                type="checkbox"
                name=""
                :id="`${button.number} cubre`"
                class="cubre"
                @change="handleCheck"
                @mouseover="handleHover"
                @mouseleave="handleLeave"
                v-if="button.number < 4 && button.number > 1"
              />
              <input
                type="checkbox"
                name=""
                :id="`${button.number} linea`"
                class="cubre"
                @change="handleCheck"
                @mouseover="handleHover"
                @mouseleave="handleLeave"
                v-if="button.number === 1"
              />
              <div
                class="HoverBtns inactive"
                :data-number="button.number"
                data-hover-number
              ></div>
            </div>
          </span>
        </div>
        <button
          @click="(e) => handleClick(e, column_armor.third)"
          data-betType="column"
          @mouseover="handleHover"
          @mouseleave="handleLeave"
          class="column"
          data-numbers="3-36"
        >
          3 - 36
        </button>
        <button
          @click="(e) => handleClick(e, column_armor.second)"
          data-betType="column"
          @mouseover="handleHover"
          @mouseleave="handleLeave"
          class="column"
          data-numbers="2-35"
        >
          2 - 35</button
        ><button
          @click="(e) => handleClick(e, column_armor.first)"
          data-betType="column"
          @mouseover="handleHover"
          @mouseleave="handleLeave"
          class="column"
          data-numbers="1-34"
        >
          1 - 34
        </button>
      </div>
      <div class="docenaChanceSimpleContainer" ref="tableSimple">
        <button
          class="firstTwelve dozen"
          @click="(e) => handleClick(e, dozen.first_dozen)"
          :value="dozen.first_dozen"
          data-betType="dozen"
          @mouseover="handleHover"
          @mouseleave="handleLeave"
          data-numbers="1-12"
        >
          1st 12nd
        </button>
        <button
          class="secondTwelve dozen"
          @click="(e) => handleClick(e, dozen.second_dozen)"
          :value="dozen.second_dozen"
          data-betType="dozen"
          @mouseover="handleHover"
          @mouseleave="handleLeave"
          data-numbers="13-24"
        >
          2nd 12nd
        </button>
        <button
          class="thirdTwelve dozen"
          @click="(e) => handleClick(e, dozen.third_dozen)"
          :value="dozen.third_dozen"
          data-betType="dozen"
          @mouseover="handleHover"
          @mouseleave="handleLeave"
          data-numbers="25-36"
        >
          3rd 12nd
        </button>
        <button
          @click="(e) => handleClick(e, chanceSimple_armor.firts)"
          :value="'1-18'"
          data-betType="half"
          class="half"
          @mouseover="handleHover"
          @mouseleave="handleLeave"
          data-numbers="1-18"
        >
          1 to 18
        </button>
        <button
          @click="(e) => handleClick(e, even_odd_armor.even)"
          data-betType="even_odd"
          class="even"
          @mouseover="handleHover"
          @mouseleave="handleLeave"
        >
          Even
        </button>
        <button
          class="red color"
          @click="(e) => handleClick(e, color_armor.red)"
          data-betType="color"
          @mouseover="handleHover"
          @mouseleave="handleLeave"
        ></button>
        <button
          class="black color"
          @click="(e) => handleClick(e, color_armor.black)"
          data-betType="color"
          @mouseover="handleHover"
          @mouseleave="handleLeave"
        ></button>
        <button
          @click="(e) => handleClick(e, even_odd_armor.odd)"
          data-betType="even_odd"
          class="odd"
          @mouseover="handleHover"
          @mouseleave="handleLeave"
        >
          Odd
        </button>
        <button
          @click="(e) => handleClick(e, chanceSimple_armor.second)"
          :value="[19, 36]"
          data-betType="half"
          class="half"
          @mouseover="handleHover"
          @mouseleave="handleLeave"
          data-numbers="19-36"
        >
          19 to 36
        </button>
      </div>
    </div>

    <div class="btns-container">
      <button class="reset-btn" @click="resetBets">RESET</button>

      <button
        class="spin-btn"
        @click="makeBets"
        data-bs-toggle="popover"
        data-bs-trigger="hover focus"
        data-bs-content="Disabled popover"
        title="APUESTA!"
        ref="betBtn"
      >
        <img
          src="https://cdn-icons-png.flaticon.com/512/566/566295.png"
          alt="SPIN"
        />
      </button>

      <div class="carousel" ref="chipsContainer">
        <button @click="decreaseToken">&leftarrow;</button>
        <div class="w-100 d-flex position-relative">
          <Chip
            v-for="(chip, index) in casinoChips"
            :key="index"
            :chip="chip"
            :class="{
              'chip-active': amount === index,
              'd-none': amount !== index,
            }"
            :data-value="chip.number"
          />
        </div>
        <button @click="increaseToken">&rightarrow;</button>
      </div>
    </div>
    <div class="resultZone">
      <!-- <RouletteButtons /> -->
      <div>
        <h2>Apuestas</h2>
        <div v-for="(element, index) in apuesta" :key="index">
          <div v-if="element.type === 'plenoNumbers'">
            <p class="m-0">Tipo de apuesta: PLENO</p>
            <p class="m-0">Numero: {{ element.number }}</p>
          </div>
          <div
            v-if="
              element.type === 'cuadroNumbers' ||
              element.type === 'semiPlenoNumbers' ||
              element.type === 'lineaNumbers' ||
              element.type === 'specialCalle' ||
              element.type === 'calleNumbers'
            "
          >
            <p class="m-0">
              Tipo de apuesta:
              {{ element.type.split('Numbers')[0].toUpperCase() }}
            </p>
            <ul>
              <li v-for="(el, index) in element.number" :key="index">
                {{ el.number }}
              </li>
            </ul>
          </div>
          <div v-if="element.type.split('_')[1] === 'dozen'">
            <p>Tipo de apuesta: {{ element.label }}</p>
          </div>
          <div
            v-if="
              element.type === 'FIRST' ||
              element.type === 'SECOND' ||
              element.type === 'THIRD' ||
              element.type === 'ODD' ||
              element.type === 'EVEN' ||
              element.type === 'BLACK' ||
              element.type === 'RED' ||
              element.type === '1-18' ||
              element.type === '19-36'
            "
          >
            <p>Tipo de apuesta: {{ element.label }}</p>
          </div>
          <div v-if="element.type === 'cubre'">
            <p>Tipo de apuesta: cubre</p>
            <p>Numeros: {{ element.number.type }}</p>
          </div>
        </div>
      </div>
      <div>
        <h2>User information</h2>
        <p>Usuario: {{ user.user_name }}</p>
        <p>
          Saldo: {{ user.balance }}
          {{ user.currency }}
        </p>
      </div>
      <div class="rounds">
        <h2>Rounds</h2>
        <div v-if="roundsIds">
          <b-table striped hover :fields="fields" :items="roundsIds"> </b-table>
        </div>
      </div>
    </div>
    <div>
      <h2>Resultados</h2>
      <div class="results">
        <div
          v-for="(element, index) in results"
          :key="index"
          :style="`background: ${element.color}`"
        >
          {{ element.result }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { io } from 'socket.io-client';
import { setBet } from './helpers/setBet';
import Swal from 'sweetalert2';
import { Launch } from '@/components';
import { Chip } from '..';
import { mapGetters, mapState } from 'vuex';

export default {
  components: { Launch, Chip },
  computed: {
    ...mapState({
      // reds: 'reports/reds'
    }),
    ...mapGetters({
      // success: "round/isSuccess",
      reds: 'reports/getReds',
    }),
  },
  data() {
    return {
      datafinal: {},
      progressMax: 0,
      progressValue: 30,
      status: true,
      buttons: [],
      time: 60,
      user: {
        user_name: 'Default',
        balance: 10,
        currency: 'EUR',
      },

      // data que se reendereiza

      dozen: {
        first_dozen: {
          type: 'first_dozen',
          value: 'FIRST',
          label: 'Primera docena',
        },

        second_dozen: {
          type: 'second_dozen',
          value: 'SECONDS',
          label: 'Segunda docena',
        },

        third_dozen: {
          type: 'third_dozen',
          value: 'THIRD',
          label: 'Tercera docena',
        },
      },

      color_armor: {
        black: {
          type: 'BLACK',
          label: 'Negros',
        },

        red: {
          type: 'RED',
          label: 'Rojos',
        },
      },
      chanceSimple_armor: {
        firts: {
          type: '1-18',
          label: '1-18',
        },
        second: {
          type: '19-36',
          label: '19-36',
        },
      },

      even_odd_armor: {
        even: {
          type: 'EVEN',
          label: 'Pares',
        },
        odd: {
          type: 'ODD',
          label: 'Impares',
        },
      },

      column_armor: {
        first: {
          type: 'FIRST',
          label: 'Primera columna',
        },
        second: {
          type: 'SECOND',
          label: 'Segunda columna',
        },
        third: {
          type: 'THIRD',
          label: 'Tercera columna',
        },
      },

      roundId: '',
      roundCode: '',
      results: [],
      apuesta: [],
      //data para el back

      plenoNumbers: [],
      semiPlenoNumbers: [],
      dozen_armor: [],
      color: [],
      even_odd: [],
      column: [],
      chanceSimple: [],
      cubre: [],
      cuadroNumbers: [],
      calleNumbers: [],
      lineaNumbers: [],
      specialCalle: [],
      bet: {},
      checkbox: false,

      showBets: false,
      amount: 0,
      intervalo: null,
      runningRound: false,
      runningInterval: false,
      fields: [
        { key: 'index', sortable: true },
        { key: 'code', sortable: false },
        { key: 'status', sortable: false },
      ],
      roundsIds: [],
      SOCKET_URL: process.env.VUE_APP_API,
      ROULETTE_ID: this.$route.params.id,
      PROVIDER_ID: this.$route.params.provider,
      doubleZeroRoulette: this.$route.params.doubleZero,

      PLAYER_ID: '',
      OPERATOR_ID: '',
      CLIENT_ID: '',
      CURRENCY_ID: '',
      USER_ID: '',
      betData: {},
      totalAmount: 0,
      platform: '',
      userAgent: '',
      isUserBetting: false,
      timeResult: 'apostar',

      timeToBet: 30,
      loading: false,
      jackpotValues: [],
      formatter: new Intl.NumberFormat('es-MX'),
      roundOpen: false,
      jackpotsOn: false,
      betMessage: 'Espere al siguiente juego',
      barColor: 'success',

      casinoChips: [],
      betsocket: null,
      socket: null,
    };
  },
  name: 'Tablero',

  methods: {
    handleDoubleZeroCheck() {
      this.doubleZeroRoulette = !this.doubleZeroRoulette;
    },
    addData() {
      const twins = [
        {
          numbers: [8, 10, 11, 13, 17, 20, 26, 29, 28, 31],
          color: 'black',
        },
        {
          numbers: [9, 12, 16, 18, 19, 21, 27, 30],
          color: 'red',
        },
      ];

      const singles = [
        {
          numbers: [2, 4, 6, 15, 22, 24, 33, 35],
          color: 'black',
        },
        {
          numbers: [1, 3, 5, 7, 14, 23, 25, 32, 34, 36],
          color: 'red',
        },
      ];

      const tableNumbers = [];

      for (let number = 1; number <= 36; number++) {
        if (singles[0].numbers.includes(number)) {
          const info = {
            number,
            color: singles[0].color,
            type: 'plenoNumbers',
            label: 'Pleno',
          };

          tableNumbers.push(info);
        }
        if (singles[1].numbers.includes(number)) {
          const info = {
            number,
            color: singles[1].color,
            type: 'plenoNumbers',
            label: 'Pleno',
          };

          tableNumbers.push(info);
        }
        if (twins[0].numbers.includes(number)) {
          const info = {
            number,
            color: twins[0].color,
            type: 'plenoNumbers',
            label: 'Pleno',
          };

          tableNumbers.push(info);
        }
        if (twins[1].numbers.includes(number)) {
          const info = {
            number,
            color: twins[1].color,
            type: 'plenoNumbers',
            label: 'Pleno',
          };

          tableNumbers.push(info);
        }
      }

      this.buttons = tableNumbers;
    },
    handleClick(e, value) {
      if (this.user.balance <= 0 || this.user.balance < this.amount) {
        Swal.fire({
          title: 'No posees fondos suficientes',
          icon: 'info',
          timer: 1500,
          position: 'center',
        });
        return;
      }

      if (!this.$refs.table) return;
      const buttons = this.$refs.table.querySelectorAll('.innerBtn');
      const chipActive =
        this.$refs.chipsContainer.querySelector('.chip-active');
      const chipValue = Number(chipActive.getAttribute('data-value'));

      if (value.type === 'plenoNumbers') {
        this.plenoNumbers.push({ number: value.number, amount: chipValue }); //TODO: Colocar el amount
        this.bet.plenoNumbers = this.plenoNumbers;
        this.apuesta.push(value);
      } else if (
        value.type === 'first_dozen' ||
        value.type === 'second_dozen' ||
        value.type === 'third_dozen'
      ) {
        const dozenValue = value.type.split('_')[0].toUpperCase();
        this.dozen_armor.push({ type: dozenValue, amount: chipValue });
        this.bet.dozens = this.dozen_armor;

        this.apuesta.push(value);
        // this.balance = this.balance - this.amount;

        if (value.value === 'FIRST') {
          for (let numb = 0; numb < 12; numb++) {
            buttons[numb].classList.add('active');
          }
        }
        if (value.value === 'SECONDS') {
          for (let numb = 12; numb < 24; numb++) {
            buttons[numb].classList.add('active');
          }
        }
        if (value.value === 'THIRD') {
          for (let numb = 24; numb < 36; numb++) {
            buttons[numb].classList.add('active');
          }
        }
      } else if (value.type === '1-18' || value.type === '19-36') {
        this.chanceSimple.push({ type: value.type, amount: chipValue });
        this.bet.chanceSimple = this.chanceSimple;
        this.apuesta.push(value);

        if (value.type === '1-18') {
          for (let number = 0; number < 18; number++) {
            buttons[number].classList.add('active');
          }
        } else {
          for (let number = 18; number < 36; number++) {
            buttons[number].classList.add('active');
          }
        }
      } else if (value.type === 'BLACK' || value.type === 'RED') {
        this.color.push({ type: value.type, amount: chipValue });
        this.bet.color = this.color;
        this.apuesta.push(value);

        if (value.type === 'BLACK') {
          if (this.$refs.table) {
            const blackBtns =
              this.$refs.table.querySelectorAll('.black .black');

            blackBtns.forEach((btn) => btn.classList.add('active'));
          }
        }
        if (value.type === 'RED') {
          if (this.$refs.table) {
            const redBtns = this.$refs.table.querySelectorAll('.red .red');

            redBtns.forEach((btn) => btn.classList.add('active'));
          }
        }
      } else if (value.type === 'ODD' || value.type === 'EVEN') {
        this.even_odd.push({ type: value.type, amount: chipValue });
        this.bet.even_odd = this.even_odd;
        this.apuesta.push(value);

        let cont = 1;
        if (value.type === 'EVEN') {
          for (let number = 0; number < 36; number++) {
            if (cont % 2 === 0) {
              buttons[number].classList.add('active');
            }
            cont++;
          }
        } else {
          for (let number = 0; number < 36; number++) {
            if (cont % 2 !== 0) {
              buttons[number].classList.add('active');
            }
            cont++;
          }
        }
      } else if (
        value.type === 'FIRST' ||
        value.type === 'SECOND' ||
        value.type === 'THIRD'
      ) {
        this.column.push({ type: value.type, amount: chipValue });
        this.bet.columns = this.column;
        this.apuesta.push(value);

        if (value.type === 'FIRST') {
          for (let number = 0; number < 34; number += 3) {
            buttons[number].classList.add('active');
          }
        }
        if (value.type === 'SECOND') {
          for (let number = 1; number < 35; number += 3) {
            buttons[number].classList.add('active');
          }
        }
        if (value.type === 'THIRD') {
          for (let number = 2; number < 36; number += 3) {
            buttons[number].classList.add('active');
          }
        }
      }

      if (value.type === 'plenoNumbers' && value.number !== 0) {
        if (e.target.classList.contains('active')) {
          e.target.classList.remove('active');
          e.target.classList.add('active');
        } else {
          e.target.classList.add('active');
        }
      }

      if (
        (value.type === 'plenoNumbers' && value.number === 0) ||
        value.number === '00'
      ) {
        if (e.target.classList.contains('secondActive')) {
          e.target.classList.remove('secondActive');
          e.target.classList.add('secondActive');
        } else {
          e.target.classList.add('secondActive');
        }
      }
    },
    handleCheck(e) {
      this.checkbox = !this.checkbox;
      const checkbox = e.target;

      if (e.target.checked) {
        const square = Number(
          e.target.closest('div').getAttribute('data-value')
        );

        const rouletteType = this.doubleZeroRoulette ? 'doubleZero' : 'normal';

        const chipActive =
          this.$refs.chipsContainer.querySelector('.chip-active');
        const chipValue = Number(chipActive.getAttribute('data-value'));

        const bet = setBet(checkbox, square, chipValue, rouletteType);

        if (bet.number instanceof Array) {
          this.apuesta.push(bet);
          this[bet.type].push(...bet.number);
        } else {
          this.apuesta.push(bet);

          this[bet.type].push(bet);
        }
      }
    },
    handleHover(e) {
      const checkbox = e.target;

      if (checkbox.classList.contains('column')) {
        const [initial, last] = checkbox
          .getAttribute('data-numbers')
          .split('-');

        const numbersToHover = [];

        for (let i = Number(initial); i <= Number(last); i += 3) {
          numbersToHover.push(i);
        }

        const numbers = document.querySelectorAll('[data-hover-number]');
        const toHover = [];

        numbers.forEach((numb) => {
          const parseHoverNumb = Number(numb.getAttribute('data-number'));
          if (numbersToHover.includes(parseHoverNumb)) {
            return toHover.push(numb);
          }
        });

        toHover.forEach((numb) => {
          numb.classList.remove('inactive');
        });

        return;
      }

      if (checkbox.classList.contains('dozen')) {
        const [initial, last] = checkbox
          .getAttribute('data-numbers')
          .split('-');

        const numbersToHover = [];

        for (let i = Number(initial); i <= Number(last); i++) {
          numbersToHover.push(i);
        }

        const numbers = document.querySelectorAll('[data-hover-number]');
        const toHover = [];

        numbers.forEach((numb) => {
          const parseHoverNumb = Number(numb.getAttribute('data-number'));
          if (numbersToHover.includes(parseHoverNumb)) {
            return toHover.push(numb);
          }
        });

        toHover.forEach((numb) => {
          numb.classList.remove('inactive');
        });

        return;
      }

      if (checkbox.classList.contains('half')) {
        const [initial, last] = checkbox
          .getAttribute('data-numbers')
          .split('-');

        const numbersToHover = [];

        for (let i = Number(initial); i <= Number(last); i++) {
          numbersToHover.push(i);
        }

        const numbers = document.querySelectorAll('[data-hover-number]');
        const toHover = [];

        numbers.forEach((numb) => {
          const parseHoverNumb = Number(numb.getAttribute('data-number'));
          if (numbersToHover.includes(parseHoverNumb)) {
            return toHover.push(numb);
          }
        });

        toHover.forEach((numb) => {
          numb.classList.remove('inactive');
        });

        return;
      }

      if (checkbox.classList.contains('even')) {
        const [initial, last] = [2, 36];

        const numbersToHover = [];

        for (let i = Number(initial); i <= Number(last); i += 2) {
          numbersToHover.push(i);
        }

        const numbers = document.querySelectorAll('[data-hover-number]');
        const toHover = [];

        numbers.forEach((numb) => {
          const parseHoverNumb = Number(numb.getAttribute('data-number'));
          if (numbersToHover.includes(parseHoverNumb)) {
            return toHover.push(numb);
          }
        });

        toHover.forEach((numb) => {
          numb.classList.remove('inactive');
        });

        return;
      }

      if (checkbox.classList.contains('odd')) {
        const [initial, last] = [1, 35];

        const numbersToHover = [];

        for (let i = Number(initial); i <= Number(last); i += 2) {
          numbersToHover.push(i);
        }

        const numbers = document.querySelectorAll('[data-hover-number]');
        const toHover = [];

        numbers.forEach((numb) => {
          const parseHoverNumb = Number(numb.getAttribute('data-number'));
          if (numbersToHover.includes(parseHoverNumb)) {
            return toHover.push(numb);
          }
        });

        toHover.forEach((numb) => {
          numb.classList.remove('inactive');
        });

        return;
      }

      if (checkbox.classList.contains('color')) {
        const numbersToHover = [
          1, 3, 5, 7, 9, 12, 14, 16, 18, 19, 21, 23, 25, 25, 27, 30, 32, 34, 36,
        ];

        const numbers = document.querySelectorAll('[data-hover-number]');
        const toHover = [];

        numbers.forEach((numb) => {
          const parseHoverNumb = Number(numb.getAttribute('data-number'));

          if (checkbox.classList.contains('red')) {
            if (numbersToHover.includes(parseHoverNumb)) {
              return toHover.push(numb);
            }
          } else {
            if (!numbersToHover.includes(parseHoverNumb)) {
              return toHover.push(numb);
            }
          }
        });

        toHover.forEach((numb) => {
          numb.classList.remove('inactive');
        });

        return;
      }

      const square = Number(e.target.closest('div').getAttribute('data-value'));

      const rouletteType = this.doubleZeroRoulette ? 'doubleZero' : 'normal';

      const bet = setBet(checkbox, square, this.amount, rouletteType);

      if (bet.type === 'cubre') {
        const numbers = bet.number.type.split('-');
        const parsedNumbers = numbers.map((numb) => {
          if (numb === '00') {
            return 37;
          }
          return Number(numb);
        });

        const hoverNumbers = document.querySelectorAll('[data-hover-number]');
        const toHover = [];
        hoverNumbers.forEach((numb) => {
          if (numb.getAttribute('data-number') === '00') {
            const parseHoverNumb = numb.getAttribute('data-number');
            if (parsedNumbers.includes(parseHoverNumb)) {
              return toHover.push(numb);
            }
          } else {
            const parseHoverNumb = Number(numb.getAttribute('data-number'));
            if (parsedNumbers.includes(parseHoverNumb)) {
              return toHover.push(numb);
            }
          }
        });

        toHover.forEach((numb) => {
          numb.classList.remove('inactive');
        });
      } else {
        const betNumbers = bet.number.map((element) => {
          if (element.number === '00') {
            return 37;
          }
          return element.number;
        });

        const numbers = document.querySelectorAll('[data-hover-number]');
        const hoverNumbers = [];
        numbers.forEach((numb) => {
          if (numb.getAttribute('data-number') === '00') {
            const parseHoverNumb = numb.getAttribute('data-number');

            if (betNumbers.includes(parseHoverNumb)) {
              return hoverNumbers.push(numb);
            }
          } else {
            const parseHoverNumb = Number(numb.getAttribute('data-number'));
            if (betNumbers.includes(parseHoverNumb)) {
              return hoverNumbers.push(numb);
            }
          }
        });

        hoverNumbers.forEach((numb) => {
          numb.classList.remove('inactive');
        });
      }
    },
    handleLeave() {
      const hoverNumbers = document.querySelectorAll('.HoverBtns');

      hoverNumbers.forEach((numb) => {
        if (!numb.classList.contains('inactive')) {
          numb.classList.add('inactive');
        }
      });
    },
    async launch(item) {
      try {
        const { operatorId, token, casinoToken, isoCode: currency } = item;

        this.OPERATOR_ID = operatorId;

        const config = {
          casinoId: this.ROULETTE_ID,
          operatorId: this.OPERATOR_ID,
          token,
          casinoToken,
          currency,
          language: 'es',
        };
        const launch = await this.$http.post('launch', config);

        if (!launch.data.ok) {
          throw new Error('Error al conectar con el launch');
        }

        this.USER_ID = launch.data.player.userId;
        this.PLAYER_ID = launch.data.player._id;
        this.CLIENT_ID = launch.data.player.client;
        const balance = Number(launch.data.player.lastBalance).toFixed(2);
        this.user.balance = balance;
        this.user.currency = currency;
        this.user.user_name = launch.data.player.username;
        this.casinoChips = launch.data.casinoChips;

        this.$refs.launchComponent.modal = false;

        this.socket = io(this.SOCKET_URL, {
          transports: ['websocket'],
          query: {
            rouletteId: this.ROULETTE_ID,
            userId: this.PLAYER_ID,
            operator: this.OPERATOR_ID,
          },
        });

        this.getTime();
      } catch (error) {
        console.log('ERROR LAUNCH', error);
      }
    },
    makeBets() {
      if (this.cuadroNumbers.length)
        this.bet = { ...this.bet, cuadroNumbers: this.cuadroNumbers };
      if (this.calleNumbers.length)
        this.bet = { ...this.bet, calleNumbers: this.calleNumbers };
      if (this.specialCalle.length) {
        this.bet = {
          ...this.bet,
          specialCalle: [
            { type: '37-0-1-2-3', amount: this.specialCalle[0].amount },
          ],
        };
      }
      if (this.lineaNumbers.length)
        this.bet = { ...this.bet, lineaNumbers: this.lineaNumbers };
      if (this.cubre.length) {
        const cubre = [];

        for (let cubr of this.cubre) {
          cubre.push({ type: cubr.number.type, amount: cubr.number.amount });
        }

        this.bet = { ...this.bet, cubre: [...cubre] };
      }

      if (this.semiPlenoNumbers.length)
        this.bet = { ...this.bet, semiPlenoNumbers: this.semiPlenoNumbers };
      if (this.dozen_armor.length)
        this.bet = { ...this.bet, dozens: this.dozen_armor };
      if (this.color.length) this.bet = { ...this.bet, color: this.color };
      if (this.chanceSimple.length)
        this.bet = { ...this.bet, chanceSimple: this.chanceSimple };

      this.getTotalAmount(this.bet);

      if (this.totalAmount > this.user.balance) {
        Swal.fire({
          title: 'No posees fondos suficientes',
          icon: 'info',
          position: 'center',
          text: `Intentaste apostar: ${this.totalAmount}. Tu fondo es de: ${this.user.balance}`,
        });
        return false;
      }

      this.addBets();
      const isValidBet = this.verifyBets();
      if (!isValidBet) return;
      if (Object.entries(this.bet).length === 0) return;

      this.betData = {
        user_id: this.USER_ID,
        player: this.PLAYER_ID,
        roulette: this.ROULETTE_ID,
        operatorId: this.OPERATOR_ID,
        clientId: this.CLIENT_ID,
        ID_Ronda:
          this.roundsIds[
            this.roundsIds.length === 0 ? 0 : this.roundsIds.length - 1
          ].round,
        identifierNumber:
          this.roundsIds[
            this.roundsIds.length === 0 ? 0 : this.roundsIds.length - 1
          ].identifierNumber,
        bet: this.bet,
        totalAmount: 0,
        platform: this.platform,
        userAgent: this.userAgent,
        currency: this.user.currency,
        lastBalance: this.user.balance,
      };

      return this.betData;
    },
    resetBets() {
      /* RESETEAR POSIBILIADADES DE APUESTA */
      this.cuadroNumbers = [];
      this.calleNumbers = [];
      this.lineaNumbers = [];
      this.semiPlenoNumbers = [];
      this.plenoNumbers = [];
      this.cubre = [];
      this.dozen_armor = [];
      this.chanceSimple = [];
      this.column = [];
      this.color = [];
      this.checkbox = false;
      this.apuesta = [];
      this.bet = {};
      /* ------------- */

      if (this.$refs.table) {
        this.$refs.table.style.pointerEvents = 'auto';
      }

      if (this.$refs.tableSimple) {
        this.$refs.tableSimple.style.pointerEvents = 'auto';
      }

      this.showBets = false;

      this.bets = {};
      this.betData = {};

      if (this.$refs.table) {
        const activeNumbers = this.$refs.table.querySelectorAll('.active');
        const activeGreens = this.$refs.table.querySelectorAll('.secondActive');
        const checkboxes =
          this.$refs.table.querySelectorAll("[type='checkbox']");
        checkboxes.forEach((chkbox) => {
          if (chkbox.checked) {
            chkbox.checked = false;
          }
        });

        if (activeNumbers.length) {
          activeNumbers.forEach((res) => res.classList.remove('active'));
        }
        if (activeGreens.length) {
          activeGreens.forEach((res) => res.classList.remove('secondActive'));
        }
      }
    },
    getTime() {
      if (this.runningInterval) {
        return;
      }

      this.socket.on(`round:start`, ({ round }) => {
        this.isUserBetting = false;
        console.log('INICIA SOCKET - round:start');

        /* LIMPIAR JACKPOTS ANTERIORES */
        this.totalAmount = 0;
        this.jackpotValues = [];
        this.resetBets();

        const { ID_Ronda, identifierNumber } = round;

        this.roundId = ID_Ronda;
        this.roundsIds.push({
          index:
            this.roundsIds.length > 0
              ? this.roundsIds[1]
                ? this.roundsIds[this.roundsIds.length - 1].index + 1
                : this.roundsIds[0].index + 1
              : 0,
          round: this.roundId,

          status: 'Corriendo ronda',
          identifierNumber,
        });
        /* ----------------------- */
        if (this.$refs.betBtn) {
          this.$refs.betBtn.disabled = false;
        }
        /* ----------------------- */
        this.roundOpen = true;
        /* ----------------------- */
        if (!this.timeToBet && this.runningInterval) {
          this.cleanBetInterval();
        }
        /* ----------------------- */
        const { start_date, end_date } = round;
        const initialTime = new Date(start_date);
        const endTime = new Date(end_date);
        const totalTime = endTime.getTime() - initialTime.getTime();
        this.timeToBet = totalTime / 1000;
        /* ----------------------- */
        this.intervalo = setInterval(() => {
          if (!this.timeToBet) {
            this.roundOpen = false;
            this.betMessage = 'APUESTAS CERRADAS';
            this.progressValue = 30;
            const bet = this.makeBets();
            if (Object.entries(this.betData).length) {
              this.socket.emit(`bet`, bet);
              this.isUserBetting = true;
            }
            setTimeout(() => {
              this.betMessage = 'ESPERE AL SIGUIENTE JUEGO';
            }, 2000);
            if (this.$refs.betBtn) {
              this.$refs.betBtn.disabled = true;
            }
            return this.cleanBetInterval();
          }
          this.runningInterval = true;
          this.timeToBet--;
          this.progressValue = this.timeToBet;
        }, 1000);
      });

      this.socket.on(`round:end`, ({ result }) => {
        this.barColor = 'info';

        this.getWinner();
        this.resetBets();
        this.betMessage = `RESULTADO: ${result}`;
        if (result === 37 && !this.doubleZeroRoulette) {
          return Swal.fire({
            title: 'Error. Resultado para otro tipo de ruleta',
            info: 'Ejecutar rollback',
          });
        }
        if (this.roundsIds.length) {
          this.roundsIds[this.roundsIds.length - 1].status = 'Ronda cerrada';
        }
        this.loading = false;
        this.results.push({
          result,
          color: this.reds.includes(result)
            ? 'red'
            : [0, 37].includes(result)
              ? 'green'
              : 'black',
        });

        this.jackpotValues = [];
      });

      this.socket.on(`jackpot-values`, ({ jackpot_values }) => {
        if (!jackpot_values.length) return;
        console.log('Jackpots', jackpot_values);
        this.betMessage = 'MULTIPLICADORES';
        this.jackpotsOn = true;
        this.barColor = 'warning';
        this.jackpotValues = jackpot_values;
      });

      this.socket.on(`bet:success`, () => {
        Swal.fire('APUESTA HECHA', '', 'success');
      });

      this.socket.on(`bet:error`, () => {
        Swal.fire('ERROR HACIENDO LA APUESTA', '', 'error');
      });

      this.socket.on('winner:err', (data) => {
        console.log('WINNER ERR', data);
      });

      this.socket.on('winner', (data) => {
        console.log('WINNER ERR', data);
      });
    },
    increaseToken() {
      const chipsCount = this.casinoChips.length - 1;
      if (this.amount === chipsCount) {
        this.amount = 0;
      } else {
        this.amount += 1;
      }
    },
    decreaseToken() {
      const chipsCount = this.casinoChips.length - 1;
      if (this.amount === 0) {
        this.amount = chipsCount;
      } else {
        this.amount -= 1;
      }
    },
    getWinner() {
      if (this.roundsIds.length > 2) {
        if (
          this.roundsIds[this.roundsIds.length - 1].round ===
          this.roundsIds[this.roundsIds.length - 2]
        ) {
          return;
        }
      }

      if (Object.entries(this.betData).length === 0) {
        if (!this.roundsIds.length) return;
        this.roundsIds[this.roundsIds.length - 1].result = 'Ronda cerrada';
        return;
      }

      if (!this.isUserBetting) return;

      this.socket.emit('winner', {
        playerId: this.PLAYER_ID,
        roulette: this.ROULETTE_ID,
        identifierNumber:
          this.roundsIds[this.roundsIds.length - 1].identifierNumber,
      });

      this.$http
        .get(`bets/winner`, {
          params: {
            playerId: this.PLAYER_ID,
            identifierNumber:
              this.roundsIds[this.roundsIds.length - 1].identifierNumber,
            roulette: this.ROULETTE_ID,
            platform: this.platform,
            lastBalance: this.user.balance,
            userAgent: this.userAgent,
            user_id: this.USER_ID,
          },
        })
        .then(({ data }) => {
          if (data.result < 0 || data.result === 99) {
            return;
          }

          if (data.result === 37 && !this.doubleZeroRoulette) {
            Swal.fire({
              title: 'Error en la ronda. Ejecutar rollback',
              info: `Resultado: ${data.result}`,
            });
          }

          if (!data.win) {
            this.totalAmount = 0;
            this.roundsIds[this.roundsIds.length - 1].result =
              `Ronda perdida. Salió: ${data.result}`;
            return Swal.fire({
              title: 'No has ganado',
              text: 'Intenta nuevamente',
              icon: 'info',
              position: 'top-right',
              showConfirmButton: false,
              timer: 1500,
            });
          }

          Swal.fire({
            title: '¡FELICIDADES!',
            text: `GANANCIA: ${data.totalEarnings}`,
            icon: 'success',
            position: 'top-right',
            timer: 1500,
          });

          this.user.balance += data.totalEarnings;
          this.totalAmount = 0;

          this.roundsIds.forEach((round, index) => {
            if (round.round === data.bets[0].round) {
              this.roundsIds[index].result = data.result;
            }
          });
        })
        .catch((err) => console.log(err));
    },
    detectDispositive() {
      const { userAgent } = navigator;
      const regex = /Android|webOS|iPhone|iPad|Blackberry|Windows Phone/i;
      const dispositive = regex.test(userAgent) ? 'mobile' : 'desktop';
      this.userAgent = userAgent;
      return (this.platform = dispositive);
    },
    getTotalAmount(bet) {
      for (let typeBet in bet) {
        if (typeBet === 'semiPlenoNumbers') {
          for (let cont = 0; cont < bet[typeBet].length; cont += 2) {
            this.totalAmount += bet[typeBet][cont].amount;
          }
        }
        if (typeBet === 'cuadroNumbers') {
          for (let cont = 0; cont < bet[typeBet].length; cont += 4) {
            this.totalAmount += bet[typeBet][cont].amount;
          }
        }
        if (typeBet === 'calleNumbers') {
          for (let cont = 0; cont < bet[typeBet].length; cont += 3) {
            this.totalAmount += bet[typeBet][cont].amount;
          }
        }

        if (typeBet === 'lineaNumbers') {
          for (let cont = 0; cont < bet[typeBet].length; cont += 6) {
            this.totalAmount += bet[typeBet][cont].amount;
          }
        }
        if (
          typeBet === 'plenoNumbers' ||
          typeBet === 'columns' ||
          typeBet === 'dozens' ||
          typeBet === 'chanceSimple' ||
          typeBet === 'even_odd' ||
          typeBet === 'specialCalle' ||
          typeBet === 'color'
        ) {
          for (let betting of bet[typeBet]) {
            this.totalAmount += betting.amount;
          }
        }

        if (typeBet === 'cubre') {
          for (let betting of bet[typeBet]) {
            this.totalAmount += betting.amount;
          }
        }
      }
    },
    addBets() {
      this.bet = {
        plenoNumbers: [],
        semiPlenoNumbers: [],
        lineaNumbers: [],
        cuadroNumbers: [],
        dozens: [],
        columns: [],
        color: [],
        even_odd: [],
        chanceSimple: [],
        cubre: [],
        specialCalle: [],
        calleNumbers: [],
        ...this.bet,
      };
    },
    verifyBets() {
      const {
        plenoNumbers,
        semiPlenoNumbers,
        lineaNumbers,
        cuadroNumbers,
        dozens,
        columns,
        color,
        even_odd,
        chanceSimple,
        cubre,
        specialCalle,
        calleNumbers,
      } = this.bet;

      if (
        !plenoNumbers.length &&
        !semiPlenoNumbers.length &&
        !lineaNumbers.length &&
        !cuadroNumbers.length &&
        !dozens.length &&
        !columns.length &&
        !color.length &&
        !even_odd.length &&
        !chanceSimple.length &&
        !cubre.length &&
        !specialCalle.length &&
        !calleNumbers.length
      )
        return false;

      return true;
    },
    cleanBetInterval() {
      return clearInterval(this.intervalo);
    },
  },
  mounted() {
    this.addData();
    this.detectDispositive();
  },
  beforeDestroy() {
    this.socket.disconnect();
    return this.cleanBetInterval();
  },
};
</script>

<style scoped>
/* .amount {
  width: 40px;
  height: 40px;
  border: 1px solid red;
  border-radius: 50%;
  background: white;
  color: red;
  display: flex;
  justify-content: center;
  align-items: center;
} */

.amount {
  position: relative;
  display: block;
  border: 6px dashed white;
  border-radius: 50%;
  line-height: 27px;
  text-align: center;
  font-family: Helvetica;
  color: white;
  width: 40px;
  height: 40px;
}

.amount:before {
  position: absolute;
  z-index: -1;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: -9px;
  border-radius: 50%;
  background: red;
  content: '';
}

.amount:after {
  position: absolute;
  z-index: -1;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: -4px;
  border: 1px dashed white;
  border-radius: 50%;
  background: rgba(0, 0, 0, 0.4);
  content: '';
}

.carousel {
  height: 50px;
  width: 50%;

  max-height: 40px;

  display: flex;
  justify-content: space-evenly;
  align-items: center;
}

#imagen {
  width: 40px;
  height: 40px;
  background-size: cover;
}

.carousel > button {
  background: gray;
  border-radius: 50%;
  width: 30px;
  height: 30px;
}

.tablero {
  display: grid;
  grid-auto-flow: column;
  grid-template-columns: 5% 90% 5%;
  grid-template-rows: repeat(3, 80px);

  width: 100%;

  justify-content: center;
}

.numbers-pleno {
  grid-column: 2/3;
  grid-row: 1/4;
  display: flex;
  flex-direction: column-reverse;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;

  /* margin: 0 30px;
  height: 97%; */
}

.numbers-pleno > span > div {
  width: 80px;
  height: 78px;
  margin: 1px 0px;
}

.spin-btn,
.reset-btn {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  border: none;
  padding: 0;
}

.btns-container {
  width: 30%;

  height: 10vh;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  transform: translateY(-100%);
}

.reset-btn {
  background: grey;
  left: 20%;
}

.spin-btn > img {
  width: 100%;
  height: auto;
}

.docenaChanceSimpleContainer {
  display: grid;
  grid-template-rows: repeat(2, 50px);
  grid-template-columns: repeat(6, 80px);
  justify-content: center;
  margin-top: 10px;
  margin-left: 100px;
}

.firstTwelve {
  grid-row: 1/2;
  grid-column: 1/3;
}

.secondTwelve {
  grid-row: 1/2;
  grid-column: 3/5;
}

.thirdTwelve {
  grid-row: 1/2;
  grid-column: 5/7;
}

.zeroBtn {
  grid-row: 1/4;
  grid-column: 1/2;
  position: relative;
  display: flex;
  flex-direction: column;
}

.zeroBtn > * {
  position: relative;
}

.zeroBtn .HoverBtns {
  top: 0;
  left: 0;
}

.zeroBtn > * {
  flex-grow: 1;
}

button {
  color: white;
  background: green;
  border: none;
}

.red {
  background: red;
}

.black {
  background: black;
}

.btn-container {
  position: relative;
  /* border: 2px solid black; */
}

.HoverBtns {
  width: 100%;
  height: 100%;
  position: absolute;
  background: linear-gradient(
    45deg,
    rgba(0, 128, 255, 0.8),
    rgba(0, 85, 255, 0.8)
  );
  z-index: 10;
}

.inactive {
  background: transparent;
}

.innerBtn {
  width: 90%;
  height: 90%;
  position: absolute;
  /* left: 20px;
  top: 30%; */
  border: none;

  background: linear-gradient(
    0deg,
    rgba(238, 174, 202, 0) 0%,
    rgba(133, 133, 133, 0.44) 100%
  );
  z-index: 30;
}

.active {
  background: linear-gradient(
    0deg,
    rgba(0, 255, 191, 0) 0%,
    rgba(220, 220, 220, 0.841) 100%
  );
}

.secondActive {
  background: linear-gradient(
    0deg,
    rgba(22, 119, 0, 1) 0%,
    rgba(40, 194, 5, 0.568) 100%
  );
}

.semipleno {
  position: absolute;
  top: 50%;
  left: -10%;
}

.calle {
  position: absolute;
  bottom: -15%;
  left: 40%;
  z-index: 1;
}

.linea {
  position: absolute;
  bottom: 0;
  right: -15%;
  z-index: 1;
}

.square {
  position: absolute;
  bottom: -15%;
  right: -15%;
  z-index: 1;
}

[type='checkbox'] {
  cursor: pointer;
  opacity: 0;
  z-index: 20;
}

[type='checkbox']:hover {
  opacity: 1;
}

[type='checkbox']:checked {
  opacity: 1;
}

.resultZone {
  display: flex;
  justify-content: space-between;
  height: 150px;
  max-height: 150px;
  overflow-y: scroll;
  padding: 0 20px;
  text-align: left;
}

.cubre {
  position: absolute;
  left: -5%;
  bottom: -5%;
  z-index: 99;
}

.loader {
  background: rgba(133, 133, 133, 0.548);
  color: #000;
}

.jackpotContainer {
  display: flex;
  flex-direction: column;

  position: absolute;
  top: 0;
  left: 0;
  width: 8%;
}

.jackpotItem {
  width: 90%;
  background: rgb(0, 0, 0);
  color: #fff;
  margin: 0.5rem auto;
  display: flex;
  flex-direction: column;
  text-align: center;
  padding: 5px 0 0 0;
  /* animation */
  /*  animation-name: jackpots;
  animation-iteration-count: infinite;
  animation-timing-function: ease-out;
  animation-duration: 0.6s; */
}

.jackpotItem > div {
  border-bottom: 1px solid darkgoldenrod;

  padding-top: 15px;
  padding-bottom: 15px;
  font-family: fantasy;
  font-size: 16px;
}

.jackpotNumber {
  background: rgba(133, 133, 133, 0.548);
  border-radius: 50%;
  width: 50px;
  height: 50px;
  margin: 0 auto;
}

.jackpotMultiplier {
  background: linear-gradient(45deg, black, #a98400);

  height: 50px;
  width: 100%;
  margin: 10px auto;
  margin-bottom: 0;
}

.results {
  display: flex;
  width: 100%;
  overflow-x: scroll;
  margin: 0 auto;
  height: 10vh;
  align-items: center;
}

.results > div {
  width: 30px;
  height: 30px;
  background: red;
  line-height: 30px;
  text-align: center;
  color: #fff;
  font-weight: 900;
  margin: 0 5px;
}

.table-container {
  transition: all 0.5s ease;
}

.openbets {
  transform: scale(1);
}

.closebets {
  transform: scale(0.6);
}

.toggle {
  align-items: center;
  border-radius: 100px;
  display: flex;
  font-weight: 700;
  margin-bottom: 16px;
}

.toggle__input {
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  overflow: hidden;
  position: absolute;
  white-space: nowrap;
  width: 1px;
}

.toggle__input:not([disabled]):active + .toggle-track,
.toggle__input:not([disabled]):focus + .toggle-track {
  border: 1px solid transparent;
  box-shadow: 0px 0px 0px 2px #222;
}

.toggle__input:disabled + .toggle-track {
  cursor: not-allowed;
  opacity: 0.7;
}

.toggle-track {
  background: rgb(163, 163, 163);
  border: 1px solid transparent;
  border-radius: 100px;
  cursor: pointer;
  display: flex;
  align-items: center;
  height: 20px;
  margin-right: 12px;
  position: relative;
  width: 60px;
}

.toggle-indicator {
  align-items: center;
  background: black;
  border-radius: 50%;
  display: flex;
  height: 90%;
  justify-content: center;
  left: 0px;
  right: initial;
  outline: solid 2px transparent;
  position: absolute;
  transition: 1s;
  width: 30%;
}

.toggle__input:checked + .toggle-track .toggle-indicator {
  left: initial;
  right: 0px;
}

.fish {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 60px;
  height: 60px;
  margin: 30px;
  border-radius: 50%;
  -webkit-border-radius: 150px;
  -moz-border-radius: 150px;
  box-shadow:
    inset 0 0 1px 1px rgba(0, 0, 0, 0.569),
    0 0 5px 1px rgba(90, 90, 90, 0.66),
    inset 0 35px 1px rgba(255, 255, 255, 0.334);
  -moz-box-shadow:
    inset 0 0 1px 1px rgba(0, 0, 0, 0.576),
    0 0 5px 1px rgba(90, 90, 90, 0.392),
    inset 0 35px 1px rgba(255, 255, 255, 0.397);
  -webkit-box-shadow:
    inset 0 0 1px 1px rgba(0, 0, 0, 0.1),
    0 0 5px 1px rgba(90, 90, 90, 0.595),
    inset 0 35px 1px rgba(255, 255, 255, 0.2);
  border-width: 8px;
  border-style: dashed;
}

.fish span {
  font-family: Verdana;
  text-align: center;
  line-height: 20px;
  font-size: 16px;
  font-weight: bolder;
}

/* @keyframes jackpots {
  from {
    transform: skew(0deg, -5deg);
  }

  to {
    transform: skew(0deg, 5deg);
  }
} */
</style>
